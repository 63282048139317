/* eslint-disable @next/next/no-img-element */
import classnames from "classnames";
import { StaticImageData } from "next/image";
import Link from "next/link";
import React, { ReactNode } from "react";

interface ContactDetailProps {
  href: string;
  icon: StaticImageData;
  children: ReactNode;
  className?: string;
  newTab?: true | false;
}

const ContactDetail: React.FC<ContactDetailProps> = ({
  href,
  icon,
  children,
  className,
  newTab,
}) => {
  return (
    // eslint-disable-next-line @next/next/link-passhref
    <Link
      href={href}
      className={classnames(className, "flex")}
      {...(newTab ? { target: "_blank" } : {})}
    >
      <div className="w-8 h-8 mr-4 inline-flex items-center">
        <img src={icon.src} alt="Contact icon" className="mx-auto" />
      </div>
      <div className="font-roboto text-lg font-light mt-[3px]">{children}</div>
    </Link>
  );
};

export default ContactDetail;
