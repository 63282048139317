import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import Image from "next/image";
import { News } from "../../redux/newsfeed/NewsfeedInterface";
import GrayCalendarIcon from "../../icons/gray-calendar.svg";
import RightAccentArrow from "../../icons/right-accent-arrow.svg";
import { parseJSON } from "date-fns";
import Link from "next/link";

export const NewsCard = ({ news }: { news: News }) => {
  const url = process.env.NEXT_PUBLIC_STRAPI_BACKEND_URL
    ? news.Glavna_slika?.formats
      ? process.env.NEXT_PUBLIC_STRAPI_BACKEND_URL +
        news.Glavna_slika.formats.thumbnail.url
      : "img/news-no-image.png"
    : "img/news-no-image.png";
  const dateObj = parseJSON(news.createdAt);
  return (
    <Link href={`/vijesti/vijest?slug=${news.slug}`} passHref>
      <div className="desktop:max-w-[536px] h-[92px] flex cursor-pointer">
        <div className="w-[92px] h-[92px] flex-shrink-0">
          {url === "" ? (
            <></>
          ) : (
            <Image
              className="w-full h-full rounded-lg"
              width={92}
              height={92}
              style={{
                objectFit: "cover",
              }}
              src={url}
              alt={"latest news"}
              loader={({ src }) => {
                return src;
              }}
            />
          )}
        </div>
        <div className="my-2 ml-3 grow">
          <div className="max-h-[57px] h-57px font-roboto font-bold text-[16px] leading-[18.75px] text-blackFlat h-[57px] max-w-[432px] block text-ellipsis overflow-hidden relative">
            <p>{news.Naslov}</p>
            <BlocksRenderer
              content={
                news.Sadrzaj && news.Sadrzaj.length > 0 ? [news.Sadrzaj[0]] : []
              }
              blocks={{
                paragraph: ({ children }) => (
                  <p className="text-[14px] font-normal">{children}</p>
                ),
              }}
            />
            {/* {news.Sadrzaj[0]} */}
          </div>
          <div className="flex justify-between mt-[5px] ">
            <div className="font-roboto font-medium text-[12px] leading-[14.06px] text-[#B0B0B0] flex items-center gap-1">
              <div>
                <Image src={GrayCalendarIcon} alt="c" width={13} height={14} />
              </div>
              {`${dateObj.getDate().toString()}.${dateObj
                .getMonth()
                .toString()}.${dateObj.getFullYear().toString()}.`}
            </div>
            <div className="font-roboto font-normal text-[12px] leading-[14.06px] text-blueAccent flex items-center gap-[6px]">
              Pročitaj više
              <Image src={RightAccentArrow} alt="c" width={5} height={10} />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
