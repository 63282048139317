import { useEffect, useState } from "react";
import { useAppSelector } from "../store";
import { selectNews } from "./newsfeedSelector";
import { News } from "./NewsfeedInterface";

export const useNews = () => {
  const [n, setN] = useState<News[]>([]);
  const news = useAppSelector(selectNews);
  useEffect(() => {
    setN(news);
  }, [news, setN]);
  return n;
};
