import { createSlice } from "@reduxjs/toolkit";
import { NewsfeedInterface } from "./NewsfeedInterface";
import { applyFetchNewsAsync } from "./fetchNewsAsync";

const initialState: NewsfeedInterface = {
  newsfeedData: [],
  newsfeedFetchStatus: "NOTFETCHED",
};

const newsfeedSlice = createSlice({
  name: "newsfeedSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    applyFetchNewsAsync(builder);
  },
});

// export const {} = newsfeedSlice.actions;

export default newsfeedSlice.reducer;
