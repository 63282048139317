import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { News, NewsfeedInterface } from "./NewsfeedInterface";
import { AppState } from "../store";
import { getAllNews } from "./newsfeedService";

export const fetchNewsAsync = createAsyncThunk<
  { data: News[] },
  undefined,
  { state: AppState }
>("newsfeedSlice/fetchNewsAsync", async () => {
  const response = await getAllNews();

  return response.data;
});

export const applyFetchNewsAsync = (
  builder: ActionReducerMapBuilder<NewsfeedInterface>
) => {
  builder.addCase(fetchNewsAsync.fulfilled, (state, action) => {
    state.newsfeedFetchStatus = "FETCHED";
    state.newsfeedData = action.payload.data;
  });
  builder.addCase(fetchNewsAsync.rejected, (state) => {
    state.newsfeedFetchStatus = "REJECTED";
  });
  builder.addCase(fetchNewsAsync.pending, (state) => {
    state.newsfeedFetchStatus = "PENDING";
  });
};
