import classNames from "classnames";
import Link from "next/link";
import React, { ReactNode } from "react";

interface ButtonProps {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  href?: string;
  color?: "blue" | "green" | "greyWhite" | undefined;
}
const Button: React.FC<ButtonProps> = ({ color, ...props }) => {
  const className = classNames(
    "z-[60] w-[210px] h-[52px] rounded-[52px] cursor-pointer hover:border-0 hover:scale-105 duration-75",
    "font-roboto-condensed font-bold text-base leading-6 uppercase inline-flex items-center justify-center",
    { "bg-blueAccent text-white": color == "blue" || color == undefined },
    { "bg-green text-white": color == "green" },
    { "bg-greyWhite text-black": color == "greyWhite" },
    props.className
  );

  return props.href ? (
    <Link href={props.href} passHref>
      <div onClick={props.onClick} className={className}>
        {props.children}
      </div>
    </Link>
  ) : (
    <button
      disabled={props.disabled}
      className={className}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default Button;
