/* eslint-disable @next/next/link-passhref */
import Link from "next/link";
import React, { ReactNode } from "react";
import { useRouter } from "next/router";
import classNames from "classnames";

interface props {
  children: ReactNode;
  href: string;
  onClick?: () => void;
}

const NavbarMenuItem: React.FC<props> = (props: props) => {
  const router = useRouter();
  const isActive = router.pathname === props.href ? "border-b" : "";

  const style = classNames(
    isActive,
    "ml-12 text-base font-bold text-white font-roboto-condensed uppercase hover:border-b"
  );
  return (
    <Link href={props.href}>
      <div onClick={props.onClick} className={style}>
        {props.children}
      </div>
    </Link>
  );
};

export default NavbarMenuItem;
