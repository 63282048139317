import axios, { AxiosResponse } from "axios";
import { News } from "./NewsfeedInterface";

export const getAllNews = (): Promise<
  AxiosResponse<{ data: News[] }, unknown>
> => {
  return axios
    .create({
      baseURL: process.env.NEXT_PUBLIC_STRAPI_BACKEND_API_URL,
      headers: {
        accept: "application/json",
        "content-type": "application/json",
      },
    })
    .get("/news?sort[0]=createdAt:desc&populate=*", { data: {} });
};
