import React from "react";
import Appointment, {
  AppointmentProps,
} from "../index-page/appointment/Appointment";
import Description, {
  DescriptionProps,
} from "../index-page/description/Description";
import Gallery, { GalleryProps } from "../index-page/gallery/Gallery";
import Hero, { HeroProps } from "../index-page/hero/Hero";
import MapSection from "../index-page/map/MapSection";
import Programs, { ProgramsProps } from "../index-page/programs/Programs";
import SliderGallery, {
  SliderGalleryProps,
} from "../index-page/slider-gallery/SliderGallery";

import { AbstractComponent } from "./SectionContainer";
import {
  LatestNews,
  LatestNewsInterface,
} from "../index-page/latest-news/LatestNews";

interface SectionRouterProps {
  section: AbstractComponent;
}

const SectionRouter: React.FunctionComponent<SectionRouterProps> = ({
  section,
}: SectionRouterProps) => {
  switch (section.__component) {
    case "page-components.hero-section":
      return <Hero {...(section as HeroProps)} />;
    case "page-components.description-section":
      return <Description {...(section as DescriptionProps)} />;
    case "page-components.slider-gallery":
      return <SliderGallery {...(section as SliderGalleryProps)} />;
    case "page-components.latest-news":
      return <LatestNews {...(section as LatestNewsInterface)} />;
    case "page-components.programs":
      return <Programs {...(section as ProgramsProps)} />;
    case "page-components.gallery-section":
      return <Gallery {...(section as GalleryProps)} />;
    case "page-components.map-section":
      return <MapSection />;
    case "page-components.create-appointment-section":
      return <Appointment {...(section as AppointmentProps)} />;
    default:
      return <></>;
  }
};

export default SectionRouter;
