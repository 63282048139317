import React from "react";
import { MdClose } from "react-icons/md";
import classNames from "classnames";
import router from "next/router";
import MenuItem from "./MenuItem";
import { motion, AnimatePresence } from "framer-motion";
import useTrackCtaAppointments from "../../../analytics-hooks/useTrackCtaAppointments";

interface MenuProps {
  isMenuVisibleHandler: (value: boolean) => void;
  isMenuVisible: boolean;
}

const Menu: React.FC<MenuProps> = (props) => {
  const styleVisible =
    "fixed top-0 flex flex-col items-center justify-center z-zMenu bg-blueAccent h-fullvh ";

  const style = classNames(styleVisible, { hidden: !props.isMenuVisible });

  const dispatchCTAAppointment = useTrackCtaAppointments();

  return (
    <AnimatePresence>
      {props.isMenuVisible && (
        <motion.div
          className={style}
          initial={{ width: 0, marginLeft: 0, overflow: "hidden" }}
          animate={{ width: "100vw" }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          exit={{ width: 0 }}
        >
          <motion.div
            initial={{ opacity: 0, marginRight: "700px" }}
            animate={{ opacity: 1, marginRight: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <MenuItem
              isMenuVisibleHandler={() => {
                props.isMenuVisibleHandler(false);
                router.push("/");
              }}
              onClickLocation="/"
            >
              Početna
            </MenuItem>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, marginRight: "700px" }}
            animate={{ opacity: 1, marginRight: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <MenuItem
              isMenuVisibleHandler={() => {
                props.isMenuVisibleHandler(false);
              }}
              onClickLocation="/skola-tenisa"
            >
              Škola tenisa
            </MenuItem>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, marginRight: "700px" }}
            animate={{ opacity: 1, marginRight: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <MenuItem
              isMenuVisibleHandler={() => {
                props.isMenuVisibleHandler(false);
              }}
              onClickLocation="/vijesti"
            >
              Vijesti
            </MenuItem>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, marginRight: "700px" }}
            animate={{ opacity: 1, marginRight: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <MenuItem
              isMenuVisibleHandler={() => {
                props.isMenuVisibleHandler(false);
              }}
              onClickLocation="/kontakt"
            >
              Kontakt
            </MenuItem>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, marginRight: "700px" }}
            animate={{ opacity: 1, marginRight: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <MenuItem
              isMenuVisibleHandler={() => {
                props.isMenuVisibleHandler(false);
              }}
              onClickLocation="/podrzite-nas"
            >
              Podržite nas
            </MenuItem>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, marginRight: "700px" }}
            animate={{ opacity: 1, marginRight: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <MenuItem
              onClick={() => {
                dispatchCTAAppointment();
              }}
              isMenuVisibleHandler={() => {
                props.isMenuVisibleHandler(false);
              }}
              onClickLocation="/zakazi-termin"
            >
              Zakaži termin
            </MenuItem>
          </motion.div>
          <motion.div
            initial={{ position: "fixed", top: 8, right: 500, opacity: 0 }}
            animate={{ right: 8, opacity: 1, rotate: 360 }}
            transition={{
              opacity: {
                delay: 0.3,
              },
              duration: 0.7,
            }}
            exit={{ left: -1400 }}
          >
            <MdClose
              className="w-12 h-12 text-white cursor-pointer z-zMenu"
              onClick={() => {
                props.isMenuVisibleHandler(false);
              }}
            />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Menu;
