/* eslint-disable @next/next/link-passhref */
import Link from "next/link";
import React, { ReactNode } from "react";

interface MenuItemProps {
  isMenuVisibleHandler: (value: boolean) => void;
  onClickLocation: string;
  children: ReactNode;
  onClick?: () => void;
}

const MenuItem: React.FC<MenuItemProps> = (props) => {
  return (
    <Link href={props.onClickLocation}>
      <div
        className="flex items-center justify-center w-48 h-10 mt-4 text-xl font-bold text-center text-white uppercase cursor-pointer font-roboto-condensed hover:bg-accent leading-lnh20 desktop:leading-lnh26"
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          }
          props.isMenuVisibleHandler(false);
        }}
      >
        {props.children}
      </div>
    </Link>
  );
};

export default MenuItem;
