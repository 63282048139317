import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { selectNewsFetchStatus } from "./newsfeedSelector";
import { fetchNewsAsync } from "./fetchNewsAsync";
import { sessionStatusSelector } from "../rendezvous-customer-redux/_internal/customer-slice/cutomerSelectors";
import { useRouter } from "next/router";

export const useFetchNewsIfNeeded = () => {
  const fetchStatus = useAppSelector(selectNewsFetchStatus);
  const sessionStatus = useAppSelector(sessionStatusSelector);
  const dispatch = useAppDispatch();
  const router = useRouter();

  useEffect(() => {
    if (fetchStatus === "NOTFETCHED") {
      dispatch(fetchNewsAsync());
    }
    // router.reload();
  }, [dispatch, fetchStatus, sessionStatus]);
};
