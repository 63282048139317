import { useFetchNewsIfNeeded, useNews } from "../../../redux/newsfeed";
import Heading2 from "../../common/text/Heading2";
import Underline, { UnderlinePositionEnum } from "../../common/Underline";
import Container from "../../layout/Container";
import { NewsCard } from "../../vijesti/NewsCard";

// import { GrayCalendarIcon } from "../../../icons";

export interface LatestNewsInterface {
  __component: string;
  id: number;
  title: string;
}

export const LatestNews: React.FC<LatestNewsInterface> = (props) => {
  useFetchNewsIfNeeded();
  const news = useNews();
  return (
    <Container>
      <div className="mb-8">
        <Heading2>{props.title}</Heading2>
        <Underline position={UnderlinePositionEnum.center} color="blueAccent" />
      </div>
      <div className="flex flex-col desktop:grid desktop:grid-cols-2 gap-4">
        {news.slice(0, 4).map((n) => (
          <NewsCard key={n.documentId} news={n} />
        ))}
      </div>
    </Container>
  );
};
